import { useEffect, useRef } from 'react';
// components
import Section from 'src/components/Section';
import Icon from 'src/components/Icon';
import Typography from 'src/components/Typography';
// animations
import { appearanceOfText } from 'src/animations/text';
import { onHTMLElementEnterOnWindow } from 'src/animations/scroll';
import { drawSvg } from 'src/animations/svg';
// hooks
import useHowItWorksData from 'src/cms/data/proofOfConcept/useHowItWorksData';

import * as style from './HowItWorks.module.scss';

const HowItWorks = () => {
  // refs
  const drawingImageRef = useRef<HTMLDivElement>(null);
  // hooks
  const { title, description } = useHowItWorksData();

  useEffect(() => {
    const animation = appearanceOfText(`.${style.text}`).pause();

    onHTMLElementEnterOnWindow(`.${style.text}`, () => {
      animation.play();
    });
  }, []);

  useEffect(() => {
    if (drawingImageRef.current !== null) {
      const animation = drawSvg(drawingImageRef.current).pause();

      onHTMLElementEnterOnWindow(drawingImageRef.current, () => {
        animation.play();
      });
    }
  }, []);

  return (
    <Section title={title} className={style.content}>
      <Typography className={style.text} variant='body'>
        {description}
      </Typography>
      <div ref={drawingImageRef} className={style.image}>
        <Icon name='howItWorks' />
      </div>
    </Section>
  );
};

export default HowItWorks;

import { FC, useEffect, useCallback, useRef, useMemo, useState } from 'react';
import Slider, { Settings } from 'react-slick';
// components
import Section from 'src/components/Section';
import Typography from 'src/components/Typography';
import CardPack from 'src/components/CardPack';
// animations
import { onHTMLElementEnterOnWindow } from 'src/animations/scroll';
import { showFromOpacity } from 'src/animations/block';
// hooks
import useAllPacksData from 'src/cms/data/proofOfConcept/useAllPacksData';
import useWindowSize from 'src/hooks/useWindowSize';

import * as style from './AllPacks.module.scss';

const AllPacks: FC = () => {
  // state
  const [activeSlide, setActiveSlide] = useState<number>(0);
  // hooks
  const { isMobile } = useWindowSize();
  const { title, cards } = useAllPacksData();
  // refs
  const sliderRef = useRef<Slider>(null);
  const cardsRef = useRef<HTMLDivElement>(null);
  // memo
  const sliderSettings = useMemo<Settings>(
    () => ({
      dots: false,
      arrows: false,
      accessibility: false,
      infinite: false,
      swipeToSlide: true,
      beforeChange: (_: number, nextSlide: number) => {
        setActiveSlide(nextSlide);
      },
    }),
    []
  );

  useEffect(() => {
    if (cardsRef.current !== null) {
      const animation = showFromOpacity(cardsRef.current, {
        duration: 3,
      }).pause();

      onHTMLElementEnterOnWindow(cardsRef.current, () => {
        animation.play();
      });
    }
  }, []);

  // todo complete styles or remove
  // useEffect(() => {
  //   const animation = appearanceOfText(`.${style.animatedText}`).pause();
  //
  //   onHTMLElementEnterOnWindow(`.${style.animatedText}`, () => {
  //     animation.play();
  //   });
  // }, []);

  const handlePageClick = useCallback(
    (slideIndex: number) => () => {
      sliderRef.current?.slickGoTo(slideIndex);
    },
    []
  );

  return (
    <Section title={title}>
      <div ref={cardsRef}>
        {isMobile ? (
          <>
            <div className={style.pagination}>
              <div onClick={handlePageClick(activeSlide - 1)}>
                <Typography className={style.paginationLink} variant='h5'>
                  {cards[activeSlide - 1]?.title}
                </Typography>
              </div>
              <Typography variant='h5'>{cards[activeSlide].title}</Typography>
              <div onClick={handlePageClick(activeSlide + 1)}>
                <Typography className={style.paginationLink} variant='h5'>
                  {cards[activeSlide + 1]?.title}
                </Typography>
              </div>
            </div>
            <Slider ref={sliderRef} {...sliderSettings}>
              {cards.map((card, index) => (
                <CardPack key={index} {...card} />
              ))}
            </Slider>
          </>
        ) : (
          <div className={style.cards}>
            {cards.map((card, index) => (
              <CardPack key={index} {...card} />
            ))}
          </div>
        )}
      </div>
    </Section>
  );
};

export default AllPacks;

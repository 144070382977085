import { FC, useEffect } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
// components
import Typography from 'src/components/Typography';
import Button from 'src/components/Button';
// animations
import { appearanceOfText } from 'src/animations/text';
import { onHTMLElementEnterOnWindow } from 'src/animations/scroll';

import type { CardPackProps } from './CardPack.interface';
import * as style from './CardPack.module.scss';

const CardPack: FC<CardPackProps> = ({ title, price, detailedInfo }) => {
  useEffect(() => {
    const animation = appearanceOfText(`.${style.animatedText}`).pause();

    onHTMLElementEnterOnWindow(`.${style.animatedText}`, () => {
      animation.play();
    });
  }, []);

  return (
    <div className={style.card}>
      <div className={style.head}>
        <Typography
          className={classNames(style.title, style.animatedText)}
          variant='h3'
        >
          {title}
        </Typography>
        <Typography
          className={classNames(style.subtext, style.animatedText)}
          variant='h4'
        >
          From
        </Typography>
        <Typography
          className={classNames(style.price, style.animatedText)}
          variant='h2'
        >
          {price}
        </Typography>
      </div>
      <div className={style.detailedInfo}>
        {detailedInfo.map((item, i) => (
          <div key={i} className={style.detailedItem}>
            <Typography
              className={classNames(style.detailedTitle, style.animatedText)}
              variant='h4'
            >
              {item.title}
            </Typography>
            <ul className={style.list}>
              {item.list.map((elem, key) => (
                <li key={key} className={style.item}>
                  <div className={style.dot} />
                  <Typography className={style.text} variant='h5'>
                    {elem}
                  </Typography>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <Link to='/contact-us'>
        <Button className={style.hoverBlock} variant='secondary' text='Get' />
      </Link>
    </div>
  );
};

export default CardPack;

import { FC, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import gsap from 'gsap';
// components
import Typography from 'src/components/Typography/Typography';
import DraggableScroll from 'src/components/DraggableScroll';
// animation
import { appearanceOfText } from 'src/animations/text';
import { showBlock } from 'src/animations/block';
import { onHTMLElementEnterOnWindow } from 'src/animations/scroll';
// services
import { leadingZero } from 'src/services/helpers';

import * as style from './StepListProgress.module.scss';
import type { Props } from './StepListProgress.interface';

const blockSize = 320;

const StepListProgress: FC<Props> = ({ steps }) => {
  // state
  const [activeLine, setActiveLine] = useState<number>(-1);
  // refs
  const lineRefs = useRef<gsap.core.Tween[]>([]);

  useEffect(() => {
    const textAnimation = appearanceOfText(`.${style.animatedText}`, {
      delayBetweenLines: 0.01,
    }).pause();

    const lines = document.querySelectorAll<HTMLDivElement>(`.${style.line}`);

    lines.forEach((line) => {
      const animation = showBlock(line, {
        duration: 0.3,
        ease: 'linear',
        onComplete: () => {
          setActiveLine((prevState) => {
            if (
              prevState &&
              (prevState + 1 === Math.floor(+window.innerWidth / blockSize) ||
                prevState + 1 >= lineRefs.current.length)
            ) {
              textAnimation.play();
            }

            return prevState + 1 >= lineRefs.current.length
              ? -1
              : prevState + 1;
          });
        },
      }).pause();

      lineRefs.current.push(animation);
    });

    onHTMLElementEnterOnWindow(`.${style.line}`, () => {
      setActiveLine(0);
    });
  }, []);

  useEffect(() => {
    if (activeLine !== -1 && lineRefs.current[activeLine]) {
      lineRefs.current[activeLine].play();
    }
  }, [activeLine]);

  return (
    <DraggableScroll withScroll={false}>
      <div className={style.draggableWrapper}>
        {steps.map((item, index) => (
          <div key={index} className={style.itemWrapper}>
            <div className={style.item}>
              <div className={style.title}>
                <Typography
                  variant='body'
                  className={classNames(style.number, style.animatedText)}
                >
                  {leadingZero(index + 1)}
                </Typography>
                <Typography
                  className={classNames(style.text, style.animatedText)}
                  variant='h4'
                >
                  {item.title}
                </Typography>
              </div>
              <div className={style.contentWrapper}>
                <div className={style.line} />
                <Typography
                  className={classNames(style.content, style.animatedText)}
                  variant='h4'
                >
                  {item.description}
                </Typography>
              </div>
            </div>
          </div>
        ))}
      </div>
    </DraggableScroll>
  );
};

export default StepListProgress;

import { FC } from 'react';
// components
import PageLayout from 'src/components/PageLayout';
import Intro from 'src/components/Intro';
// hooks
import usePoCIntroData from 'src/cms/data/proofOfConcept/usePoCIntroData';
import useProofOfConceptMetaData from 'src/cms/data/proofOfConcept/useProofOfConceptMetaData';

// sections
import HowItWorks from './sections/HowItWorks';
import AllPacks from './sections/AllPacks';
import WeekZero from './sections/WeekZero';
import { PageProps } from 'gatsby';

const ProofOfConcept: FC<PageProps> = ({ location }) => {
  // hooks
  const { title, mobileTitle, description } = usePoCIntroData();
  const proofOfConceptMetaData = useProofOfConceptMetaData();

  return (
    <PageLayout metaData={{ ...proofOfConceptMetaData, href: location.href }}>
      <Intro
        title={title}
        mobileTitle={mobileTitle}
        description={description}
        icon='pocDrawing'
      />
      <HowItWorks />
      <WeekZero />
      <AllPacks />
    </PageLayout>
  );
};

export default ProofOfConcept;
